
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"
import { BoxType } from "@/store/modules/BoxInfoModule"
import $ from 'jquery'
import type { DrawerProps } from 'element-plus'

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("商品列表", ["商品管理;products"])
    })

    const drawer = ref(false)

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      product_brand: "",
      ip: "",
      is_presale: "",
      name: ""
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0
    })

    const query = () => {
      store
        .dispatch(Actions.GET_PRODUCTS, {
          ...formData.value,
          ...page.value,
        })
        .then(() => {
          tableData.value = store.getters.currentProducts;
          console.log(tableData.value)
          page.value.totalResult = store.getters.currentProductCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    query()

    const submitEvent = () => {
      query()
    }

    const newProduct = () => {
      router.push({ name: "product_new" })
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.product_brand = ''
      formData.value.ip = ''
      formData.value.is_presale = ''
      formData.value.name = ''
    }

    const onDownload = () => {
      store
        .dispatch(Actions.GET_DOWNLOAD_PRODUCTS)
        .then(() => {
          const blob = new Blob([store.getters.currentDownloadProducts], { type: "application/xlsx" });
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = `products_${formatDate(Date().toString())}.xlsx`
          link.click();
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            minute = '' + d.getMinutes(),
            second = '' + d.getSeconds()

        if (month.length < 2) 
            month = '0' + month
        if (day.length < 2) 
            day = '0' + day
        if (hour.length < 2) 
            hour = '0' + hour
        if (minute.length < 2) 
            minute = '0' + minute
        if (second.length < 2) 
            second = '0' + second

        return [year, month, day, hour, minute, second].join('')
    }

    const onUpload = () => {
      console.log('onUpload')
      $("#btn_upload").click()
    }

    const onChange = (e) => {
      console.log('onChange')

      store
        .dispatch(Actions.UPLOAD_PRODUCTS, {
          file: e.target.files[0]
        })
        .then(() => {
          Swal.fire({
            text: "上传成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const product_brand_options = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_PRODUCT_BRAND_OPTIONS)
      .then(() => {
        product_brand_options.value = [{key: '', value: ''}]
        product_brand_options.value = product_brand_options.value.concat(store.getters.currentProductBrandOptions)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const product_ip_options = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_PRODUCT_IP_OPTIONS)
      .then(() => {
        product_ip_options.value = [{key: '', value: ''}]
        product_ip_options.value = product_ip_options.value.concat(store.getters.currentProductIpOptions)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const presale_options = ref(Array<BoxType>())
    presale_options.value = [{key: '', value: ''}, {key: '是', value: 'true'}, {key: '否', value: 'false'}]


    const onDelete = (id) => {
      Swal.fire({
        title: '确认删除？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '确认',
        // denyButtonText: '取消',
        cancelButtonText: '取消'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_PRODUCT, id)
            .then(() => {
              query()
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
          // Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }

    const onAbandon = (id) => {
      Swal.fire({
        title: '确认弃用？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '确认',
        // denyButtonText: '取消',
        cancelButtonText: '取消'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.ABANDON_PRODUCT, id)
            .then(() => {
              query()
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
          // Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }

    const dialogVisible = ref(false)
    const current_image_url = ref('')
    const currentProductId = ref('')
    const currentRelativeBoxInfos = ref([] as any)

    const queryRelativeBoxInfo = (id) => {
      if (currentProductId.value !== id) {
        store
        .dispatch(Actions.GET_RELATIVE_BOX_INFOS, id)
        .then(() => {
          currentRelativeBoxInfos.value = store.getters.currentRelativeBoxInfos
          drawer.value = true
          currentProductId.value = id
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
      } else {
        drawer.value = true
      }
    }

    return {
      tableData,
      formData,
      submitEvent,
      newProduct,
      page,
      onPageChange,
      onCancel,
      product_brand_options,
      product_ip_options,
      presale_options,
      onDownload,
      onUpload,
      onChange,
      onDelete,
      onAbandon,
      dialogVisible,
      current_image_url,
      drawer,
      queryRelativeBoxInfo,
      currentRelativeBoxInfos
    }
  },
})
